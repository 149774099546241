<template>
  <Transition name="fade">
    <div
      v-if="isOpen"
      ref="panel"
      :aria-expanded="isOpen"
      arial-labelledby="panel-title"
      role="dialog"
      :class="{
        'min-h-[100dvh]': contentLoading,
      }"
      class="c-panel fixed left-0 top-[--globalHeaderHeightMobile] z-30 flex max-h-dvh w-full border-t-2 bg-white shadow-lg lg:top-[--globalHeaderHeightDesktop] lg:h-auto"
    >
      <div
        class="c-panel relative flex w-full flex-col gap-8 px-0 pt-9 lg:pt-12"
      >
        <div class="container flex flex-col gap-6">
          <button class="self-end text-gray-700" @click="emit('close')">
            <span class="sr-only">Close panel</span>
            <UiIcon icon="close" size="md" />
          </button>

          <h2
            v-if="!contentLoading"
            id="panel-title"
            class="text-2xl font-bold"
          >
            <slot name="title" />
          </h2>
        </div>
        <UiLoadingIndicator
          v-show="contentLoading"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        />
        <slot name="content" />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

defineProps<{
  isOpen: boolean
  contentLoading?: boolean
}>()

defineSlots<{
  title(): HTMLElement
  content(): HTMLElement
}>()

const emit = defineEmits<{ open: []; close: [] }>()

const panel = ref<HTMLElement | null>(null)

onClickOutside(panel, (event) => {
  if ((event.target as HTMLElement).dataset.searchButton === 'true') {
    emit('open')
    return
  }

  emit('close')
})

onMounted(() => {
  window.addEventListener('keyup', onKeyup)
})

onUnmounted(() => {
  window.removeEventListener('keyup', onKeyup)
})

function onKeyup(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    emit('close')
  }
}
</script>

<style lang="postcss" scoped>
.c-panel {
  max-height: calc(100dvh - var(--globalHeaderHeightMobile));

  @screen lg {
    max-height: calc(100dvh - var(--globalHeaderHeightDesktop));
  }
}

.c-label {
  @apply text-sm font-medium text-gray-700;
}
</style>
