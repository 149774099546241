<template>
  <header
    :class="{
      sticky: !isCondensedNavigation && !isHubPage,
      'lg:h-[--globalHeaderHeightDesktop]': !isCondensedNavigation,
      'lg:h-[--globalHeaderHeightDesktopCondensed]': isCondensedNavigation,
    }"
    class="top-0 z-40 h-[--globalHeaderHeightMobile] bg-white"
  >
    <div class="container flex h-full items-center gap-4">
      <button
        v-if="!isLandingPageContentType"
        :class="{
          'lg:hidden': !isCondensedNavigation,
        }"
        class="relative"
        data-action="toggleNavigationMenu"
        @click="toggleNavigationMenu()"
      >
        <span class="sr-only">Toggle Menu</span>
        <UiIcon
          icon="hamburger"
          size="md"
          class="ease-out-expo pointer-events-none text-gray-700 transition-transform duration-700"
        />
      </button>

      <div
        :class="{
          'lg:flex-grow-0': showSearchButton && !isCondensedNavigation,
        }"
        class="flex flex-grow items-center"
      >
        <NuxtLink v-if="!isLandingPageContentType" to="/" class="inline-block">
          <GlobalLogo
            class="w-[7.75rem] translate-y-[2px] transform lg:w-[8.5rem]"
          />
        </NuxtLink>
        <GlobalLogo
          v-else
          class="w-[7.75rem] translate-y-[2px] transform lg:w-[8.5rem]"
        />
      </div>

      <div
        v-if="!isCondensedNavigation && showSearchButton"
        class="relative lg:flex-grow lg:pl-2"
      >
        <UiButton
          :label="searchButtonText"
          :data-attributes="[{ 'search-button': true }]"
          theme="muted"
          variant="outline"
          right-icon="search"
          size="md"
          class="hidden lg:block"
          @click="onSearchButtonClick"
        />

        <UiButton
          :label="searchButtonText"
          :data-attributes="[{ 'search-button': true }]"
          theme="muted"
          variant="outline"
          right-icon="search"
          size="sm"
          class="lg:hidden"
          @click="onSearchButtonClick"
        />
      </div>

      <GlobalNavigationDesktop v-if="!isCondensedNavigation" />
      <GlobalNavigationDesktopCondensed v-if="isCondensedNavigation" />

      <div class="hidden items-center gap-2 lg:flex">
        <UiIcon icon="phone" size="md" class="text-gray-700" />

        <a
          :href="`tel:${isNzCommunity ? nzPhone : phone}`"
          class="text-sm font-bold text-gray-900"
        >
          {{ isNzCommunity ? nzPhone : phone }}
        </a>
      </div>
    </div>

    <GlobalNavigationMobile />
    <PropertyFiltersPanel />
  </header>
</template>

<script setup lang="ts">
import {
  isContentTypeLandingPageStoryblok,
  isContentTypeHubStoryblok,
} from '@/utils/sb-content-type-refiners'

// we need to wait for the story to be ready before we can use it
await useCurrentStoryStore().isReady

const { phone, searchInputPlaceholderText, propertySearchPage, nzPhone } =
  await useConfigStore().asyncConfig()
const searchButtonText = computed(
  () => searchInputPlaceholderText.value || 'Search',
)
const { communityContent, story, isNzCommunity } = storeToRefs(
  useCurrentStoryStore(),
)
const isCondensedNavigation = computed(() => !!communityContent.value)
const { toggleHamburgerMenu: toggleNavigationMenu, togglePropertyFilters } =
  useUIStore()

const showSearchButton = computed(
  () => useSlug().slugString.value !== propertySearchPage.value.cached_url,
)
const isLandingPageContentType = computed(() =>
  isContentTypeLandingPageStoryblok(story.value),
)
const isHubPage = computed(() => isContentTypeHubStoryblok(story.value))

function onSearchButtonClick(): void {
  togglePropertyFilters()
}
</script>
