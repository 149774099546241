<template>
  <UiPanel
    :is-open="isPropertyFiltersVisible"
    :content-loading="filtersLoading"
    class="lg:min-h-[911px]"
    @open="togglePropertyFilters(true)"
    @close="togglePropertyFilters(false)"
  >
    <template #title>Find a property</template>
    <template #content>
      <LazyPropertyFilters @loaded="onLoaded" />
    </template>
  </UiPanel>
</template>

<script setup lang="ts">
const { togglePropertyFilters } = useUIStore()
const { isPropertyFiltersVisible } = storeToRefs(useUIStore())

const filtersLoading = ref(true)

function onLoaded() {
  filtersLoading.value = false
}
</script>
